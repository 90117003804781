export const rollingTexts = [
  "반복되는 업무가 지겨운 지원이도",
  "내 아이디어로 돈 벌고 싶은 정현이도",
  "개발자 없이 앱 만들고 싶은 소영이도",
  "ChatGPT 잘 쓰고 싶은 동현이도",
  "대한민국을 코딩의 땅으로",
];

type iconType = {
  src: string;
  title: string;
  nextUrl: string;
  badge?: string;
  name?: string;
};

export const CategoryIcons = (): iconType[] => {
  return [
    {
      src: "/assets/images/home/v4/main_icon/coding_moonhak.png",
      title: "코딩문학제",
      name: "코딩문학제",
      nextUrl: `https://spartacodingclub.kr/site/codingmoonhak-2024`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_kprt.png",
      title: "IT 자격증",
      nextUrl: `https://spartacodingclub.kr/site/certificate-sparta`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_kdc.svg",
      title: "국비 강의",
      nextUrl: `${process.env.BASE_URL}/nb`,
      badge: "0원수강",
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_kdt.svg",
      title: "국비 취업",
      nextUrl: "https://nbcamp.spartacodingclub.kr/",
      badge: "전액무료",
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_programming.svg",
      title: "개발",
      nextUrl: `${process.env.BASE_URL}/catalog?selectedKeyword=개발`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_ai.svg",
      title: "AI ∙ GPT",
      nextUrl: `${
        process.env.BASE_URL
      }/catalog?selectedKeyword=${encodeURIComponent("AI ∙ GPT")}`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_datav2.svg",
      title: "데이터",
      nextUrl: `${process.env.BASE_URL}/catalog?selectedKeyword=데이터`,
    },
    {
      src: "/assets/images/home/v4/main_icon/menu_job.svg",
      title: "취업 ∙ 자격증",
      nextUrl: `${
        process.env.BASE_URL
      }/catalog?selectedKeyword=${encodeURIComponent("취업 ∙ 자격증")}`,
    },
  ];
};

export type CourseCardType = {
  courseId: string;
  keyword: string;
  title: string;
  description: string;
  price: string;
  url: string;
};

export type CourseRecommendType = {
  title: string;
  description: string;
  courseCards: CourseCardType[];
};

export type StartToEndCardType = {
  title: string;
  description: string;
  url: string;
  tagName: string;
  badgeColor: string;
};

export type NbcTrackCardType = {
  track_key: string;
  track_title: string;
  track_tag: string;
  track_description: string;
  track_link: string;
};

export const StartToEndCardData: StartToEndCardType[] = [
  {
    tagName: "무료",
    badgeColor: "#F5B200",
    title: "무료특강",
    description: "재밌게 코딩 맛보고 싶다면?\n쉬운 1시간 분량 만들기 강의",
    url: `${process.env.BASE_URL}/catalog?selectedKeyword=전체&onlyFree=1&filter_option=무료&selectedminor=전체`,
  },
  {
    tagName: "국비지원",
    badgeColor: "#57B094",
    title: "온라인 강의",
    description: "성취감 가득! 실습으로 익히는\n4~6주 과정 기초 강의",
    url: "/catalog?selectedKeyword=전체",
  },
  {
    tagName: "국비지원",
    badgeColor: "#57B094",
    title: "취업 부트캠프",
    description: "IT 직군 취업을 꿈꾼다면,\n4개월 몰입형 부트캠프로",
    url: "https://nbcamp.spartacodingclub.kr/",
  },
];
